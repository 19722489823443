// Overrides CoreUI & Bootstrap Variables

//
// Color system
//

$white:                           #fff;
$gray-100:                        #f0f3f5;
$gray-200:                        #c2cfd6;
$gray-300:                        #a4b7c1;
$gray-400:                        #869fac;
$gray-500:                        #678898;
$gray-600:                        #536c79;
$gray-700:                        #3e515b;
$gray-800:                        #29363d;
$gray-900:                        #151b1e;
$black:                           #000;
// $blue:                            #01558D; // VECCHIO LOGO
$blue:                            #0070c0;
$indigo:                          #6610f2;
$purple:                          #6f42c1;
$pink:                            #e83e8c;
// $red:                             #E30613; // VECCHIO LOGO
$red:                             #d64541;
$orange:                          #ffa229;
$yellow:                          #ffc107;
$green:                           #79c447;
$teal:                            #20c997;
$cyan:                            #67c2ef;

$primary: #0061A1;

// Import default variables
@import "node_modules/@coreui/coreui-pro/scss/variables";

$theme-colors: (
  "primary": $primary,
  "primary-dark": darken($primary, 8%),
  "primary-light": lighten($primary, 10%),
  "text-primary": $primary,
  "gray": $gray-400,
  "gray-dark": $gray-600,
  "gray-light": $gray-200,
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:              true;
$enable-rounded:                  true;

// Body
//
// Settings for the `<body>` element.

$body-bg:                         #e4e5e6;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:                  0.875rem;

// Breadcrumbs

$breadcrumb-bg:                   #fff;
$breadcrumb-margin-bottom:        1.5rem;

// Cards

$card-border-color:               $gray-200;
$card-cap-bg:                     $gray-100;

// Dropdowns

$dropdown-padding-y:              0;
$dropdown-border-color:           $gray-200;
$dropdown-divider-bg:             $gray-100;

// Buttons

$btn-secondary-border:            $gray-300;

// Progress bars

$progress-bg:                     $gray-100;

// Tables

$table-bg-accent:                 $gray-100;
$table-bg-hover:                  $gray-100;

// Forms

$input-group-addon-bg:            $gray-100;
$input-border-color:              $gray-200;
$input-group-addon-border-color:  $gray-200;

// core overrides

$border-color:                        $gray-200;

// Navbar

$navbar-brand-width:                  200px;
$navbar-brand-bg:                     theme-color("primary");
$navbar-brand-logo-size:              70px auto;
$navbar-brand-border: (
    bottom: (
        size:                         1px,
        style:                        solid,
        color:                        darken(theme-color("primary"),5%)
    )
);

$navbar-brand-minimized-bg:           $navbar-brand-bg;
$navbar-brand-minimized-border:       $navbar-brand-border;

// Sidebar

$sidebar-color:                       $gray-800;
$sidebar-bg:                          #fff;
$sidebar-header-bg:                   transparent;
$sidebar-footer-bg:                   transparent;
$sidebar-borders: (
  right: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
);
$mobile-sidebar-width:                200px;

// Sidebar Navigation

$sidebar-nav-title-color:             $gray-300;
$sidebar-nav-title-padding-y:         .9rem;
$sidebar-nav-link-color:              $gray-800;

$sidebar-nav-link-active-bg:          theme-color("primary");
$sidebar-nav-link-active-icon-color:  #fff;

$sidebar-nav-dropdown-color:          $gray-800;
$sidebar-nav-dropdown-bg:             darken($gray-100,5%);

// Sidebar Minimizer

$sidebar-minimizer-bg:                $gray-100;
$sidebar-minimizer-borders: (
  top: (
    size:                             1px,
    style:                            solid,
    color:                            $border-color
  )
);

$sidebar-minimizer-hover-bg:          theme-color("primary");